<template>
  <!-- TOP Nav Bar -->
  <div>
    <div class="nabvar">
      <img
          alt=""
          class="group"
          src="../../../assets/images/svg/Zoomi_logo.svg"
          @click="goToHomePage"
      />
      <div class="relative-wrapper-one">
        <p class="sensory" @click="goToHomePage">sensory</p>
        <img
            alt=""
            class="dsc-0113"
            :src=profileUrl
        />
      </div>
      <p class="sam-syed-creative-di">
        <span class="sam-syed-creative-di-emphasis-1"
        >{{userName}}</span>
      </p>
      <p class="designation">{{designation}}</p>
      <div class="routes">
        <img
            alt="myDesk"
            class="fill-1"
            src="../../../assets/images/svg/my_desk.svg"
        />
        <p class="my-desk">
          My Desk<strong class="my-desk-emphasis-2"> </strong>
        </p>
        <router-link id="peerlink" :to="{name: 'social.peers'}">
          <img
              alt="peers"
              class="fill-1-two"
              src="../../../assets/images/svg/Peers.svg"
          />
          <p class="peers">Peers</p>
        </router-link>
        <router-link id="influencerLink" :to="{name: 'social.influencers'}">
          <img
              alt=""
              class="fill-1-three"
              src="../../../assets/images/svg/Influencers.svg"
          />
          <p class="influencers">Influencers</p>
        </router-link>
        <div id="messagesLink" @click="openMessageModal">
          <img
              alt=""
              class="fill-1-four"
              src="../../../assets/images/svg/message.svg"
          />
          <p class="messages">Messages</p>
          <div class="unread-notification-container" v-if="unreadNotifications>0">
            <p class="notification-number" >{{unreadNotifications}}</p>
          </div>
        </div>
      </div>
      <div class="search-elements">
        <b-form>
          <b-form-input type="search" class="searchbar" placeholder="Search..."></b-form-input>
        </b-form>
      </div>
      <b-dropdown id="dropdown-right" right variant="primary" class="m-2 dropdown-menu-nav">
        <b-dropdown-item @click="logout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>

  <!-- TOP Nav Bar END -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NavBarStyle1',
  mounted: function () {
    this.timer = setInterval(() => {
      this.pollNotifications()
    }, 100000)
  },
  components: {
    // Lottie
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getNotification',
      'getNotificationCount'

    ]),
    unreadNotifications () {
      return this.getNotificationCount
    }
  },
  data () {
    return {
      globalSearch: '',
      showSearch: false,
      showMenu: false,
      userName: '',
      unread: 0,
      designation: '',
      profileUrl: ''
    }
  },
  methods: {
    ...mapActions([
      'GET_NOTIFICATIONS'
    ]),
    miniSidebar () {
      this.$emit('toggle')
    },
    pollNotifications () {
      console.log('Polling Notification')
      this.GET_NOTIFICATIONS()
    },
    goToHomePage () {
      this.$router.push({ name: 'social.list' }
      )
    },
    gotoInfluencerPage () {
      this.$router.push({ name: 'social.influencers' })
    },
    openMessageModal () {
      this.$store.dispatch('SET_MESSAGE_MODAL_STATE', true)
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('lxp_auth_token')
      this.$router.push({ name: 'auth1.sign-in1' })
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
    }
  },
  created () {
    this.pollNotifications()
    document.addEventListener('click', this.closeSearch, true)
    this.userName = this.getUser.name
    this.designation = this.getUser.jobTitle
    if (this.getUser.profileUrl) {
      this.profileUrl = this.getUser.profileUrl
    } else {
      this.profileUrl = require('../../../assets/images/svg/placeholderprofile.svg')
    }
  }
}
</script>
<style scoped>
.nabvar {
  position: fixed;
  z-index: 200;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: flex-start;
  height: 63px;
  width: 100%;
}
.group {
  width: 148px;
  height: 30px;
  margin-top: 4px;
  margin-right: 19px;
  cursor: pointer;
}
.relative-wrapper-one {
  margin-right: 12px;
  position: relative;
}
.sensory {
  cursor: pointer;
  height: 27px;
  width: 178px;
  font-family: "Roboto" !important;
  font-weight: 300 !important;
  font-size: 17px;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: absolute;
  left: -150px;
  bottom: -10px;
  letter-spacing: 6.46px;
  margin-top:25px ;
  margin-bottom: 2px !important;
}
.dsc-0113 {
  width: 45px;
  height: 45px;
  position: relative;
  border-radius: 4px;
  margin-top: 8px;
}
.routes {
  display: flex;
  flex-direction: row;
}
.sam-syed-creative-di {
  width: 188px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 22px;
  color: white;
  display: flex;
  align-items: center;
  padding-top: 1vH;
  margin-top: -1px;
  margin-right: 54px;
  margin-left: 15px;
}
::placeholder {
  color: white;
}
.designation {
  font-family: Roboto !important;
  font-weight: 400 !important;
  position: absolute;
  font-size: 16px;
  color: white;
  margin: 26px 0px 0px 238px;
}
.sam-syed-creative-di-emphasis-1 {
  font-family: "Roboto" !important;
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 22px;
  color: white;
}
.fill-1 {
  width: 22px;
  height: 18px;
  margin-top: 22px;
  margin-right: 6px;
}
.my-desk {
  height: 23px;
  width: 67px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 24px;
  color: rgba(0, 184, 67, 1);
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 12px;
}
.my-desk-emphasis-2 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 24px;
  color: white;
}
.fill-1-two {
  width: 16px;
  height: 16px;
  margin-top: 23px;
  margin-right: 7px;
}
.peers {
  height: 23px;
  width: 48px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 24px;
  color: #ff9721;
  display: flex;
  align-items: center;
  margin: -19px 11px 0px 20px;
}
#peerlink.router-link-exact-active {
  text-decoration: solid underline #ff9721 5px;
  text-underline-offset: 10px;
}
.fill-1-three {
  width: 16px;
  height: 20.07px;
  margin-top: 22px;
  margin-right: 7px;
}
.influencers {
  height: 23px;
  width: 79px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 24px;
  color: rgba(135, 222, 248, 1);
  display: flex;
  align-items: center;
  margin: -22px 11px 0px 20px;
}
#influencerLink.router-link-exact-active {
  text-decoration: solid underline rgba(135, 222, 248, 1) 5px;
  text-underline-offset: 10px;
}
.fill-1-four {
  width: 20px;
  height: 16px;
  margin-top: 24px;
  margin-right: 5px;
}
.messages {
  height: 23px;
  width: 78px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 24px;
  color: red;
  display: flex;
  align-items: center;
  margin: -20px 5px 0px 26px;
}
#messagesLink{
  cursor: pointer;
}
#messagesLink.router-link-exact-active {
  text-decoration: solid underline red 5px;
  text-underline-offset: 10px;
}
.adv-search {
  margin-left: .5vW;
  margin-top: 16px;
  height: 30px;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
}
.unread-notification-container {
  position: absolute;
  margin-left: 95px;
  margin-top: -22px;
  padding-bottom: 24px;
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: red;
}
.notification-number {
  color: black;
  font-weight: bolder;
}
.searchbar {
  border: 0.5px solid rgba(22, 156, 222, 1) !important;
  border-radius: 5px !important;
  width: 24vW;
  font-family: "Roboto" !important;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 24px;
  margin-top: 16px;
  margin-left: 15px;
  height: 30px !important;
}
.search-elements {
  display: flex;
  flex-direction: row;
}
.dropdown-menu-nav {
  position: fixed;
  right: 11px;
  height: 30px;
  margin-top: 16px !important;
}

@media only screen and (max-width: 600px) {
  .routes {
    display: none;
  }
  .search-elements {
    display: none;
  }
}

</style>

<template>
  <div>
    <div class="wrapper">
      <NavBarStyle1 title="Dashboard" :homeURL="{ name: 'dashboard1.home' }" @toggle="sidebarMini" >
      </NavBarStyle1>
      <div id="content-page" class="home-page">
        <div>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBarStyle1 from '../components/socialvue/navbars/NavBarStyle1'
import SideBarItems from '../FackApi/json/SideBar'
import { socialvue } from '@/config/pluginInit'
import { mapActions } from 'vuex'
export default {
  name: 'Layout1',
  components: {
    NavBarStyle1
  },
  mounted () {
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  watch: {
  },
  // sidebarTicket
  data () {
    return {
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
        { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
      ],
      verticalMenu: SideBarItems
    }
  },
  methods: {
    sidebarMini () {
      socialvue.triggerSet()
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('lxp_auth_token')
      this.$router.push({ name: 'auth1.sign-in1' })
    },
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction'
    })
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .home-page {
    max-height: 20%;
    padding-top: 20px;
  }
</style>
